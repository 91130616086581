import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Success = () => (
    <Layout>
        <Seo title="E-Mail versendet" />
        <div className="max-w-lg mx-auto text-gray-800 p-4">
            <div className="mt-20 mb-10">
                <h1>E-Mail erfolgreich versendet</h1>
                <p className="text-4xl md:text-5xl">Vielen Dank für deine Nachricht.</p>
            </div>
            <Link
                to="/"
                className="font-semibold text-glp-blue underline"
            >
                Zur Startseite
            </Link>
        </div>
    </Layout>
);

export default Success;
